<template>
  <div v-if="projectSelected">
    <section>
      <hero-global
        title="Iniciativa"
        :subtitle="projectSelected.name"
        first-width="65"
        last-width="35"
        breadcrumb="CERMI - PROYECTOS CERMI - Iniciativa"
        img="/img/cermi-token/cermi-token_1.svg"
      />
    </section>

    <div class="container p-2-rem p-top-0-px">
      <img v-if="projectSelected.image.url" :src="projectSelected.image.url" :alt="projectSelected.name" class="img-head" />
      <section>
        <div class="grid-full">
          <div class="grid right">
            <ribbon text="Leer" />
            <div class="div-video" v-if="projectSelected.youtube_link_embed">
              <iframe
                :src="'https://www.youtube.com/embed/' + youtube_parser(projectSelected.youtube_link_embed)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <p class="subtitle" v-if="projectSelected.description">{{ projectSelected.description }}</p>
          <template v-if="this.store.easyRead.active">
            <EditorJs :object="projectSelected.easy_reader" />
          </template>

          <template v-else>
            <EditorJs :object="projectSelected.normal_reader" />
          </template>
        </div>
      </section>
      <section class="section__links-interest container" v-if="projectSelected.banners.length != 0">
        <section class="section__title">
          <p class="text-big">Enlaces de interés</p>
        </section>
        <agile :options="myOptions" ref="carousel" class="carrousel-links">
          <a
            :href="route.url"
            :title="'Ir a ' + route.url"
            class="strap__item"
            v-for="(route, key) in projectSelected.banners"
            :key="key"
            target="_blank"
          >
            <div class="cont-title">
              <p class="title color-white">{{ route.name }}</p>
            </div>
            <img class="img-carrousel" v-if="route.image.url" :src="route.image.url" alt="Imagen carrousel" />
          </a>
        </agile>
      </section>
      <template v-if="store.projects">
        <div class="container-projects">
          <h2 class="title-grand">Otros Proyectos</h2>
          <section v-for="(project, key) in store.projects" :key="key" :id="project.slug" class="container">
            <blue-card
              v-if="slug != key"
              :title="project.name"
              :img="project.logo.url"
              :text="project.description"
              btn-text="Saber más"
              :idSlug="project.slug"
            />
          </section>
        </div>
      </template>
      <section>
        <carrousel2 :dataContent="$tm('translations.carrouselData.cermi')" />
      </section>
      <section>
        <other-links-interest class="links-interest-box container" />
      </section>
    </div>
  </div>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import Ribbon from "@/components/Global/Ribbon";
import { buildOptions } from "@/helpers/agileHelper";
import BlueCard from "@/components/Card/BlueCard";
import Carrousel2 from "@/components/Global/Carrousel2";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import moment from "moment";
import EditorJs from "@/components/Global/editorJs.vue";
import { contentStore } from "@/stores/contents";
import { VueAgile } from "vue-agile";
export default {
  name: "CermiToken",
  props: ["slug"],
  components: {
    OtherLinksInterest,
    Carrousel2,
    Ribbon,
    HeroGlobal,
    EditorJs,
    BlueCard,
    agile: VueAgile,
  },
  setup() {
    const store = contentStore();
    store.loadProjects();
    return {
      store,
    };
  },
  data: () => ({
    myOptions: {
      responsive: [
        {
          breakpoint: 900,
          settings: {
            autoplay: false,
            fade: false,
            navButtons: true,
            slidesToShow: 4,
            dots: false,
            speed: 2000,
            unagile: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            autoplay: false,
            fade: false,
            navButtons: true,
            slidesToShow: 1,
            dots: false,
            speed: 2000,
            unagile: false,
          },
        },
      ],
    },
  }),
  mounted() {},
  methods: {
    getExpositionBySlug() {
      let t = this;
      this.store
        .loadProject({
          slug: this.slug,
        })
        .then(function (result) {
          return (t.projectSelected = result);
        });
    },
    currentDateTime(date) {
      return moment(date).locale("es").format("L");
    },
    youtube_parser: function (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
  computed: {
    projectSelected() {
      let project = null;
      if (this.store.projects[this.slug]) {
        project = this.store.projects[this.slug];
      } else {
        project = this.getExpositionBySlug();
      }

      return project;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/general";

.img-head {
  height: auto;
  object-fit: contain;
  object-position: center center;
  width: 100%;
  margin-bottom: 40px;
}

.grid-full {
  display: inline-block;
  width: 100%;

  .grid.right {
    margin-left: 40px;
    margin-bottom: 35px;
    width: 40%;
    float: right;
  }
}

.div-video {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 90%;
    min-height: 250px;
  }
}

.container-projects {
  margin: 80px 0;

  .title-grand {
    margin-bottom: -48px;
  }
}

.box-content {
  background: transparent;

  .inner-container {
    padding-left: 0 !important;

    ul {
      li {
        border-bottom: none;
      }
    }
  }

  li {
    padding: 0 !important;
  }

  label::before,
  .item-content.-one::before {
    bottom: -10px;
    right: 0;
  }
}
.strap__item {
  height: auto;
}
.strap__item img {
  max-height: 150px !important;
  object-position: center center !important;
  object-fit: contain !important;
}
// Comentar en el caso de que haya más de 3 banner y sea carrusel
.section__links-interest.container {
  margin-top: 30px;
}
.agile--disabled.agile--no-nav-buttons.agile.carrousel-links {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 30px;
}

@media screen and (max-width: 895px) {
  .section__links-interest.container {
    padding-bottom: 0;
    padding-top: 20px;
    margin-bottom: 0;
  }
}
</style>
